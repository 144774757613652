* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: white;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* =================================
 * Custom fonts
 * ================================= */

@font-face {
  font-family: "Northstar";
  src: local("NorthStar"),
    url("./assets/fonts/northstar/northstar-outline-italic.otf")
      format("opentype");
}

// @font-face {
//   font-family: "Beon";
//   src: local("Beon"), url("/fonts/beon/beon.woff") format("woff");
// }
/* =================================
 * Typography
 * ================================= */

h1 {
  font-family: "Northstar", sans-serif;
  margin: 0;
  font-size: calc(100vw / 11);
}

h2 {
  font-family: "Beon";
  letter-spacing: 0.2rem;
  font-size: 2.25rem;
  font-weight: normal;
}

// Desktop View
@media (min-width: 1000px) {
  h1 {
    font-size: 6rem;
  }
}

// Body style
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  background-image: url("./assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

// Transparent black background
.container {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  background-color: rgba($color: #000000, $alpha: 0.8);
  clip-path: polygon(100% 0, 100% 95%, 98% 100%, 0 100%, 0 0);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

// Contains header + main
.centeredcontent {
  padding: 80px;
  padding-bottom: 0;
  flex-grow: 1;
  height: calc(100% - 70px);
  overflow-y: auto;
}

// Contains our list of links
.main {
  padding: 0 80px;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-y: auto;

  ul {
    width: 100%;
    max-width: 680px;
    padding: 0;
  }
}

// Simple footer
.footer {
  display: flex;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
  height: 5%;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

// Mobile
@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }

  .centeredcontent {
    padding: 30px;
    overflow: auto;
  }

  .main {
    padding: 0;
  }

  .container {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    clip-path: polygon(100% 0, 100% 93%, 95% 100%, 0 100%, 0 0);
  }
  .footer {
    height: 7%;
  }
}
