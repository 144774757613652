.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// Glitching for our text
.glitch {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  text-align: center;

  span {
    font-weight: bold;
    grid-row-start: 1;
    grid-column-start: 1;
    --stack-height: calc(100% / var(--stacks) - 1px);
    --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
    --clip-top: calc(var(--stack-height) * var(--index));
    --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
    clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
    animation: stack 340ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
        calc(var(--index) * 120ms),
      glitch 2s ease infinite 2s alternate-reverse;

    &:nth-child(odd) {
      --glitch-translate: 8px;
    }

    &:nth-child(even) {
      --glitch-translate: -8px;
    }
  }
}

.subheading {
  font-size: 1.25rem;
}

// Mobile
@media (max-width: 600px) {
  .subheading {
    font-size: 0.9rem;
  }
}

// Animations
@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 2px #fff, 0 0 6px #fff, 0 0 11px #fff,
      0 0 15px rgb(0, 225, 255), 0 0 25px rgb(0, 225, 255),
      0 0 35px rgb(0, 225, 255), 0 0 40px rgb(0, 225, 255),
      0 0 50px rgb(0, 225, 255);
  }
  20%,
  24%,
  55% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  4%,
  100% {
    text-shadow: none;
    transform: none;
  }
}

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  }
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}
