.link {
  list-style: none;
  border: 1px solid #00000000;
  border-radius: 10px;
  background-color: #00000088;
  margin: 15px 0;
  transition: border 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 1.5rem;
  box-shadow: 0 0 8px 1px #00000000;

  &:hover {
    border: 1px solid rgb(255, 60, 245);
    background-color: #000000cc;
    box-shadow: 0 0 8px 1px rgb(255, 60, 245);
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
  }

  svg {
    font-size: 2.5rem;
  }

  p {
    margin: 0;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .link {
    p {
      font-size: 1rem;
    }
  }
}
